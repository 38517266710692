/*****************************************************************************/
//  This module translate the text to the appropriate language
//  It contains five settings to be selected
/*****************************************************************************/

import tranStr from './Translate';

export const langs = [
    { id: 'tc', text: '繁' },
    { id: 'sc', text: '简' },
    { id: 'en', text: 'English' }
];  

// Return langId if is a valid option; return the default otherwise
// Use the first language as the default
export const checkLang = (langId, def=0) => ((langs.some((l) => l.id === langId)) ? langId : langs[def].id)

export default function processLang(item, lang) {
    let str = ''
    if (item) {
        // The item is an array of 2-4 elements:
        //      Element 0: 'en' (English)
        //      Element 1: 'tc' (Traditonal Chinese)
        //      Element 2: 'sc' (Simplified Chinese)
        // If 'sc' is not provided, translate 'tc'.
        switch (lang) {
            default:
            case 'en': str = item[0];                                   break;
            case 'tc': str = item[1];                                   break;
            case 'sc': str = (item[2] ? item[2] : tranStr(item[1]));    break;
        }
    }
    return str
}
