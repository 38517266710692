/*****************************************************************************/
//  This is the frame for all the pages.
//  It consists of the Header (Home page only), Menu Bar, Category Bar (Menu page only) and the page content.
/*****************************************************************************/

import { useCallback, useEffect, useState } from 'react'
import { NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
//
import { CategoryButton } from '../components/CatBar'
import Image from '../components/Image'
import LangSel from '../components/LangSel'
import { drinks } from '../language/ItemTokens'
import { checkLang } from '../language/Langs'
import { useLocalStorageValue  } from '../Utils'
// Pages
import Home from './Home'
import Menu from './Menu'
import Item from './Item'
import Cart from './Cart'
// css
import 'bootstrap/dist/css/bootstrap.css'
import '../css/App.css'

const PATH_HOME = 'home'
const PATH_MENU = 'menu'
const PATH_ITEM = 'item'
const PATH_CART = 'cart'

export default function Main() {
  // Get the language setting from the params of URL in the form of "path?lang=en"
  let langParam = new URLSearchParams(useLocation().search).get('lang')
  //console.log('langParam1 =', langParam)
  langParam = checkLang(langParam)
  //console.log('langParam2 =', langParam)
  // If it has been run before, use the previously saved language setting
  const [lang, setLang] = useLocalStorageValue(langParam, 'LANG')
  const changeLang = useCallback((newLang) => {
      setLang(newLang)
  }, [setLang])

  const navigate = useNavigate()
  const location = useLocation()
  const [path, setPath] = useState('')
 
  useEffect(() => {
    //console.log('location pathname = ', location.pathname)
    setPath(location.pathname)
  }, [location.pathname])

  function isPath(p) {
    //console.log('isPath path = (', path, ') p = (', p, ')');
    return (path === p  ||  path === '/' + p)
  }
 
  function gotoPage(page) {
    //console.log('goto ', page);
    setPath(page)
    navigate(page)
  }

   // Need to use the dynamic value of the window width
   const [winWidth, setWinWidth] = useState(window.innerWidth)

  window.addEventListener('resize', function() {
    //console.log('Resized: ', window.innerWidth);
    setWinWidth(window.innerWidth)
})

  const [showCats, setShowCats] = useState(true)
  const [category, setCategory] = useState(0)
  const [cartItem, setCartItem] = useState({}) // cartItem is an item object
  const [cart, setCart] = useState([]) // cart is an array of cartItem objects
  const [itemIndex, setItemIndex] = useState(1)
  const [showBanner, setShowBanner] = useState(false)

  const data = {
    lang,
    showBanner, setShowBanner,
    showCats, setShowCats,
    category, setCategory,
    cartItem, setCartItem,
    itemIndex, setItemIndex,
    cart, setCart,
    winWidth,
    gotoPage,
    swiped,
  }

  // Just for debugging
  // useEffect(() => {
  //   console.log('App cart = ', JSON.stringify(cart))
  // }, [cart])

  const Header = () => (
    <div className='app-header'>
      <Image id='teaisland' />
    </div> 
  )

  function toggleShowCats() {
    setShowCats(! showCats)
  }

  // Go to the previous or next category
  // inc = 1 -> Next; inc = -1 -> Prev
  function incCategory(inc) {
    const MAX = drinks.length - 1
    const cat = category + inc
    setCategory(cat < 0 ? MAX : cat > MAX ? 0 : cat)
  }

  // Shorthand functions for clicking the buttons or swiping
  function changeCategory(e) { incCategory(e.target.id === 'prev' ? -1 : 1) }
  function swiped(isLeft) { incCategory(isLeft ? 1 : -1) }

//<button className='category-inc-button' id='-1' onClick={changeCategory}>◀</button>
//<button className='category-inc-button' id='1' onClick={changeCategory}>▶</button>

  function CategoryPanel() {
    return (<div>
      <Image cls='model-icon' id='prev' clicked={changeCategory}/>
      <CategoryButton showCats={showCats} index={category} clicked={toggleShowCats} lang={lang} />
      <Image cls='model-icon' id='next' clicked={changeCategory}/>    
    </div>)
  }

  // No more isActive property since the current link is not displayed
  const PageLink = (({id, char}) => (
    <NavLink to={id} className='menu-button'>
      <span>{char}</span>
    </NavLink>
  ))

  const Pages = ({lang}) => (
    <div className='app-body'>
      <Routes>
      <Route exact path='/' element={<Menu data={data}/>} />
      <Route path={PATH_HOME} element={<Home lang={lang}/>} />
      <Route path={PATH_MENU} element={<Menu data={data}/>} />
      <Route path={PATH_ITEM} element={<Item data={data}/>} />
      <Route path={PATH_CART} element={<Cart data={data}/>} />
      <Route path='*' element={<Menu data={data}/>} />
    </Routes>
  </div>
  )

  // The header is shown only on the Home page
  // The current page link icon is not displayed
  return (
    <div className='app-main'>
      { isPath(PATH_HOME) && <Header /> }
      {/*<div>W: {window.innerWidth} H: {window.innerHeight}</div>*/}
      <div className='menu-bar' >
        <div>
        { ! isPath(PATH_HOME) && <PageLink id='home' char='🏠'/> }
        { ! isPath(PATH_MENU) && <PageLink id='menu' char='🥤'/> }
        { ! isPath(PATH_CART) && <PageLink id='cart' char='🛒'/> }
        </div>
        <div className='category-container'>
          { isPath(PATH_MENU) && <CategoryPanel />}
        </div>
        <div>
          <LangSel changeLang = {changeLang} lang={lang} />
        </div>
      </div>
      <Pages lang={lang}/>
    </div>
  )
}
