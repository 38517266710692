/*****************************************************************************/
//  This is the Image component
//  The img file name is used as the id
//  The folder is either 'drinks' or 'toppings' that contains the corresponding images.
/*****************************************************************************/

// A lot of work to remove the broken icon of the alt text When the image is not found
export function removeBrokenIcon(e) {
  const elem = e.target;
  // No need to fix if there is no alt text
  if (! elem.alt)
    return;
  const parent = elem.parentNode;
  parent.insertBefore(document.createTextNode(elem.alt), elem);
  parent.removeChild(elem);
}

// id: Id for this image
// folder: Name of the folder that contains this image or undefined if it is not in a folder
// cls: ClassName of the image
// img: If folder is spacified (), there is no image file if img is set to false
// clicked: Function called when the image is clicked
export default function Image({id, folder, cls, img, clicked}) {
  // 
  if (folder && ! img) {
    return <></>
  }

  const path =  './assets/' + (folder ? folder + '/' : '') + id + '.png'
  return <img 
    id={id}
    src={path}
    alt='' 
    className={cls}
    onClick={clicked}   
    onError={removeBrokenIcon}
  />
}