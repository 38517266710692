import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Input from 'react-phone-number-input/input'
import  {	isValidPhoneNumber } from 'react-phone-number-input'
//
import Image from '../components/Image'
import { formatPrice, formatText, formatToken, useLocalStorageValue } from '../Utils'

// item: Object to be deleted
// action: Function called when the Yes button is clicked
// lang: Language code
export function DeleteItem({item, action, lang}) {
  const [show, setShow] = useState(false)
  
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const onYes = () => {
    handleClose()
    action(item.id)
  }
  
  // Tried to change the closebutton color but failed
  // So remove it and put a new button in
  return (
    <>
      <span>
        <Image cls='model-icon' id='trash32' clicked={handleShow}/>
      </span>
  
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className='modal-header'>
          <Modal.Title>{formatText(item.n, lang)}</Modal.Title>
          <button className='close-btn' onClick={handleClose}>X</button>
        </Modal.Header>
        <Modal.Body>{formatToken('delete', lang)}</Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            {formatToken('no', lang)}
          </Button>
          <Button variant="success" onClick={onYes}>
            {formatToken('yes', lang)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

// total: Total amount of the order
// action: Function called when the button is clicked
// lang: Language code
export function OrderItem({total, action, lang}) {
  const [show, setShow] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [phone, setPhone] = useLocalStorageValue(undefined, 'PHONE')

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useEffect(() => {
    // Enable the Order button when a valid phone number is entered
    const valid = phone && isValidPhoneNumber(phone)
    //console.log('p: ', phone, ' valid = ', valid);
    setIsDisabled(! valid)
  }, [phone])

  function order() {
    // The input display is different from the actual phone string
    const value = document.querySelector('input').value
    //console.log('Order phone: ', value);
    action(value)
    handleClose()
  }

  // Tried to change the closebutton color but failed
  // So remove it and put a new button in
  return (
    <>
      <div className='flex-container button' onClick={handleShow}>
        <span className='grow8'>{formatToken('order', lang)}</span>
        <span className='bold'>{formatPrice(total)}</span>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className='modal-header order'>
          <Modal.Title>{formatToken('order', lang) + ' ' + formatPrice(total)}</Modal.Title>
            <button className='close-btn' onClick={handleClose}>X</button>
        </Modal.Header>
        <Modal.Body>
          <div>{formatToken('phone', lang) + ':'}</div>
          <Input country='US'
                 value={phone} // The value of the input is controlled by the state 'inputValue'
                 onChange={setPhone} // The input calls handleInputChange every time the user types
            />
        </Modal.Body>
        <Modal.Footer>
          <button className='flex-container button' disabled={isDisabled} onClick={order}>
            <span>{formatToken('order', lang)}</span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

//onChange={handleInputChange} // The input calls handleInputChange every time the user types

