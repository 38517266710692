/*****************************************************************************/
//  This is the content of the Menu page
//  It contains the drinks of the selected category
/*****************************************************************************/

import { useEffect } from 'react'
//
import Banner from '../components/Banner'
import CategoryBar from '../components/CatBar'
import Image from '../components/Image'
import { sweetness } from '../components/Sweetness'
import { temperatures } from '../components/Temperature'
//import Swipe from '../components/Swipe'
import { addOrRemoveClass, formatPrice, formatText, formatToken, setBodyColor } from '../Utils'
import { drinks } from '../language/ItemTokens'

// data: Object passed from Main
// data.swiped: Function called when a swipe is detected (must include the Swipe component)  
export default function Menu({data}) {
  const lang = data.lang
  const showBanner = data.showBanner
  const showCats = data.showCats
  const category = data.category
  const cat = drinks[category]
  //const [showBanner, setShowBanner] = useState(false)

  // useEffect(() => {
  //   data.setShowBanner = setShowBanner
  // }, [data])

  // Set the button with the selected category to have the selected property (border)
  useEffect(() => {
    document.querySelectorAll('.category-button').forEach(btn => {
      // NOTE: The id and category must be of the same type (string) or it won't work
      addOrRemoveClass((parseInt(btn.id) === category), btn, 'selected')
    })
    setBodyColor(cat.bki)
  }, [category, cat.bki])

  // useEffect(() => {
  //   const handleResize = () => {
  //     console.log('Size changed!');
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //       window.removeEventListener("resize", handleResize);
  //   };
  // })

  function changeCat(e) {
    data.setCategory(parseInt(e.currentTarget.id))
    data.setShowCats(false)
  }

  function selectItem(e) {
    const id = e.currentTarget.id
    const item = Object.assign(cat.list.findItem(id))
    item.id = id + category // include the category index to make sure the id is distinct
    item.index = undefined
    item.name = formatText(item.n, lang)
    item.price = formatPrice(item.p)
    item.qty = 1
    item.bk = cat.bk
    item.lightBk = cat.lightBk
    item.sweet = (cat.noSweet || item.noSweet ? undefined : sweetness[0])
    item.temp = (cat.noTemp || item.noTemp ? undefined : temperatures[0])
    item.toppings = []
    item.toppPrice = 0
    data.setCartItem(item)
    //console.log('item: ', JSON.stringify(item))
    data.gotoPage('item')
  }

  // Some Oolong Milk Tea names are too long for small screens
  // so shorten them accordingly
  function formatName(name) {
    name = formatText(name, lang)
    if (lang === 'en') {
      //console.log('width = ', data.winWidth, 'length = ', name.length);
      if (data.winWidth < 300 && name.length > 15 && name.indexOf('Oolong') >= 0) {
        name = name.replace('Oolong Milk Tea', 'O M T')
      }
      else if (data.winWidth < 350 && name.length > 24) {
        name = name.replace('Milk Tea', 'M T')
      }
    }
    return name
  }

  return (<div>
    {showCats && <CategoryBar clicked={changeCat} lang={lang} />}
    {showBanner && <Banner 
                    text={formatToken('received', lang)}
                    bkColor='blue' 
                    lightBk={false} 
                    onClose={() => data.setShowBanner(false)}  
                    ack={true}     
    />}
    <div id='menu-list' className='list-item-flexbox'>
      {/*<Swipe swiped={data.swiped} parentId='menu-list'/>*/}
      {cat.list.data.map((item) => ( 
        <div className='list-item' key={item.id} id={item.id} onClick={selectItem}>
          <Image cls='col-img' folder='drinks' id={item.id} img={item.img} />
          <span className='col-name'>{formatName(item.n)}</span>
          <span className='col-price'>{formatPrice(item.p)}</span>
        </div>
      ))}
    </div>
  </div>)
}