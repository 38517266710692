/*****************************************************************************/
//  This is the LangSel component
/*****************************************************************************/

import React, { useState, useEffect } from 'react';
//
import Image from '../components/Image'
import { langs } from '../language/Langs'

// The Language Selector component
function LangSel({lang, changeLang}) {
  const [show, setShow] = useState(false);
  
  useEffect(() => {
    // Close the buttons if the click is outside (made it modal)
    const hideButtons = ((e) => {
      if (e.target.id !== 'langsel') {
        setShow(false)
      }
    })
    if (! show) return;
    window.addEventListener('click', hideButtons)
    return () => window.removeEventListener('click', hideButtons)
  }, [show])

  // Change the language when one of the button is clicked
  const clicked = (id) => {
    setShow(false);
    changeLang(id); 
  }

  const langButton = (id, text) => (<span key={id} 
                                    className={'menu-lang-button' + (id === lang ? ' active' : '')}
                                    onClick={() => clicked(id)}>
                                      {text}
                                    </span>)

  return (<>
    <div id='modal' className={'menu-lang-buttons' + (show ? ' show' : '')}>
      {langs.map((l) => langButton(l.id, l.text))}
    </div>
    <div className='menu-langsel'>      
      <Image id='langsel' cls='img-langsel' clicked={() => setShow(! show)}/>
    </div>
  </>); 
}

export default React.memo(LangSel)
