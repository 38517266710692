/*****************************************************************************/
//  This is the Toppings component
//  It is used on the Item page
//  It contains the list of toppings; each can be selected
/*****************************************************************************/

import { toppingTokens } from '../language/ItemTokens'
import { formatPrice, formatText } from '../Utils'

// data: Object passed from Main
// toppings: Array of topping objects currently selected
// changed: Function called when the toppings list changes
export default function Toppings({data, toppings, changed}) {
  const lang = data.lang
  const isSelected = ((id) => (toppings && toppings.includes(id)))
  
  function selectItem(e) {
    const id = e.currentTarget.id
    let t;
    if (isSelected(id)) {
      t = toppings.filter((item) => (item !== id))
    }
    else {
      toppings.push(id)
      t = toppings.slice()
    }
    changed(t)
  }

  const Topping = (({item}) => {
    const selected = isSelected(item.id)
    const circle = (selected ? <span>✅</span> : <span>➕</span>)
    const cls = 'list-item topping' + (selected ? ' selected' : '')
    return (
      <div className={cls} key={item.id} id={item.id} onClick={selectItem}>
        {/* <Image cls='col-img' folder='toppings' id={item.id} img={item.img} /> */}
        <span className='col-name topping'>{formatText(item.n, lang)}</span>
        <span className='col-circle topping'>{circle}</span> 
       <span className='col-price topping'>{formatPrice(item.p)}</span>
      </div>
    )
  })

  return (<div>
      <div className='list-item-flexbox'>
        {toppingTokens.list.data.map((item) => (
          <Topping key={item.id} item={item} />
        ))}
      </div>
    </div>)
}
