/*****************************************************************************/
//  This is the Quantity component
//  It is used on the Cart page
//  It contains the '-' and '+' buttons and the quantity is displayed in between
/*****************************************************************************/
import { useState } from 'react'

// item: Object that contains this Quantity component
// qtyChanged: Function called when the quantity changes
export default function Quantity({item, qtyChanged}) {
  const [qty, setQty] = useState(item.qty)

  const MAX_QTY = 25
  function setNumber(e) {
    const value = (e.currentTarget.id === '-' ? -1 : 1)
    const newQty = (value ? Math.min(Math.max(qty + value, 0), MAX_QTY) : 0)
    setQty(newQty)
    qtyChanged(item, newQty)
  }

  return (
    <>
      {qty > 1 && <span className='qty-button' onClick={setNumber} id='-'>➖</span>}
      <span className='qty-text'>{qty}</span>
      {qty < MAX_QTY && <span className='qty-button' onClick={setNumber} id='+'>➕</span>}
    </>
  )
}
