/*****************************************************************************/
//  This file contains the list of categories of drinks and toppings
/*****************************************************************************/

// Constructor of an object with the findItem() method
function ItemList(data) {
  this.data = data
  this.findItem = ((id) => this.data.find((item) => item.id === id))
  this.getName = ((id) => this.findItem(id).n)
  this.getPrice = ((id) => parseInt(this.findItem(id).p))
}

// bk is the background color of the button
// bki is the background color of the body

const juice = {
  n: [ 'Juice/Milk', '果汁/牛奶' ],
  id: 'jm',
  bk: 'hsl(40, 100%, 50%)',
  bki: 'hsl(40, 100%, 75%)',
  list: new ItemList([
  { id: 'kl', n: [ 'Kumquat Lemon Juice',    '金桔檸檬汁' ], p: 500 }, // 金橘
  { id: 'lh', n: [ 'Lemon Honey Juice',      '蜂蜜檸檬汁' ], p: 500 },
  { id: 'jh', n: [ 'Grass Jelly Honey',      '仙草蜜' ],     p: 525 },
  { id: 'ha', n: [ 'Honey Aloe Juice',       '蜂蜜蘆薈' ],   p: 525 },
  { id: 'la', n: [ 'Lemon Ai-Yu Juice',      '檸檬愛玉' ],   p: 525 },
  { id: 'gb', n: [ 'Green (Mung) Bean Milk', '綠豆牛奶' ],   p: 525 },
  { id: 'rb', n: [ 'Red Bean Milk',          '紅豆牛奶' ],   p: 525 },
  { id: 'jm', n: [ 'Grass Jelly Milk',       '仙草奶凍' ],   p: 525 },
  { id: 'am', n: [ 'Avocado Milk',           '牛油果牛奶' ], p: 650 },
  { id: 'hg', n: [ 'Hot Grass Jelly',        '燒仙草' ],     p: 550 },
  ])
}

const freshTea = {
  n: [ 'Fresh Tea', '鮮泡茶' ],
  id: 'bt',
  bk: 'hsl(328, 100%, 50%)',
  bki: 'hsl(328, 100%, 75%)',
  list: new ItemList([
    { id: 'as', n: [ 'Assam Black Tea',       '阿薩姆紅茶' ],   p: 500 },
    { id: 'jm', n: [ 'Jasmine Green Tea',     '茉香綠茶' ],     p: 500 },
    { id: 'ol', n: [ 'Oolong Tea',            '高山烏龍茶' ],   p: 500 },
    { id: 'po', n: [ 'Peach Oolong',          '白桃烏龍茶' ],   p: 500 },
    { id: 'oo', n: [ 'Osmanthus Oolong',      '桂花烏龍茶' ],   p: 500 },
    { id: 'gr', n: [ 'Grape Oolong Tea',      '白葡萄烏龍茶' ], p: 500 },
    { id: 'os', n: [ 'Osmanthus Black Tea',   '桂花紅茶' ],     p: 500 },
    { id: 'gm', n: [ 'Genmaicha',             '玄米茶' ],       p: 500, x: true },
    { id: 'hj', n: [ 'Hojicha',               '日式烤茶' ],     p: 500, x: true },
    { id: 'dr', n: [ 'Dark Roast Oolong Tea', '碳燒烏龍茶' ],   p: 500 },
    { id: 'cl', n: [ 'Ceylon Black Tea',      '錫蘭紅茶' ],     p: 500 },
    { id: 'bt', n: [ 'Black Tea',             '紅茶' ],         p: 500 },
    { id: 'eg', n: [ 'Earl Grey Black Tea',   '伯爵紅茶' ],     p: 500 },
    { id: 'fs', n: [ 'Four Seasons Tea',      '四季香茶' ],     p: 500 },
    { id: 'rt', n: [ 'Rose Tie Guan Yin',     '玫瑰鐵觀音茶' ], p: 500 },
  ])
}

const flavoredTea = {
  n: [ 'Flavored Tea', '鮮味茶' ],
  id: 'gt',
  bk: 'hsl(128, 82%, 50%)',
  bki: 'hsl(128, 82%, 75%)',
  lightBk: true,
  list: new ItemList([
    { id: 'ho', n: [ 'Honey Green Tea',         '蜂蜜綠茶' ],   p: 525 },
    { id: 'gf', n: [ 'Grapefruit Green Tea',    '葡萄柚綠茶' ], p: 525 },
    { id: 'kq', n: [ 'Kumquat Green Tea',       '金桔綠茶'],    p: 525 },
    { id: 'mg', n: [ 'Mango Green Tea',         '芒果綠茶' ],   p: 525 },
    { id: 'sb', n: [ 'Strawberry Green Tea',    '草莓綠茶' ],   p: 525 },
    { id: 'pf', n: [ 'Passion Fruit Green Tea', '百香果綠茶' ], p: 525 },
    { id: 'pe', n: [ 'Peach Green Tea',         '水蜜桃綠茶' ], p: 525 },
    { id: 'lc', n: [ 'Lychee Green Tea',        '荔枝綠茶' ],   p: 525 },
    { id: 'hd', n: [ 'Honeydew Green Tea',      '哈密​​瓜綠茶' ], p: 525 },
    { id: 'yg', n: [ 'Yogurt Green Tea',        '優格綠茶' ],   p: 525 },
    { id: 'lm', n: [ 'Lemon Black Tea',         '蜂蜜紅茶' ],   p: 525 },
    { id: 'wm', n: [ 'Winter Melon Oolong Tea', '冬瓜烏龍茶' ], p: 525 },
  ])
}

const milkTea = {
  n: [ 'Milk Tea', '奶茶' ],
  id: 'mt',
  bk: 'hsl(27, 80%, 50%)',
  bki: 'hsl(27, 80%, 75%)',
  list: new ItemList([
    { id: 'as', n: [ 'Assam Milk Tea',             '阿薩姆奶茶' ],     p: 525 },
    { id: 'jm', n: [ 'Jasmine Milk Tea',           '茉香奶茶' ],       p: 525 },
    { id: 'cl', n: [ 'Ceylon Milk Tea',            '錫蘭奶茶' ],      p: 525 },
    { id: 'gm', n: [ 'Genmaicha Milk Tea',         '玄米奶茶' ],      p: 525, x: true },
    { id: 'hj', n: [ 'Hojicha Milk Tea',           '日式烤奶茶' ],    p: 525, x: true },
    { id: 'eg', n: [ 'Earl Grey Milk Tea',         '伯爵奶茶' ],      p: 525 },
    { id: 'os', n: [ 'Osmanthus Milk Tea',         '桂花奶茶' ],      p: 525 },
    { id: 'fs', n: [ 'Four Seasons Milk Tea',      '四季香奶茶' ],    p: 525 },
    { id: 'th', n: [ 'Thai Milk Tea',              '泰式奶茶' ],      p: 525, noSweet: true },
    { id: 'ta', n: [ 'Taro Milk Tea',              '芋香奶茶' ],      p: 525 },
    { id: 'bu', n: [ 'Black Sugar Milk Tea',       '黑糖奶茶' ],      p: 525 },
    { id: 'cf', n: [ 'Coffee Milk Tea',            '咖啡奶茶' ],       p: 525 },
    { id: 'ho', n: [ 'Honey Milk Tea',             '蜂蜜奶茶' ],       p: 525 },
    { id: 'mc', n: [ 'Matcha Milk Tea',            '抹茶奶茶' ],       p: 525 },
    { id: 'bs', n: [ 'Black Sesame Milk Tea',      '黑芝麻奶茶' ],     p: 525 }, 
    { id: 'rt', n: [ 'Rose Tie Guan Yin Milk Tea', '玫瑰鐵觀音奶茶' ], p: 525 },
  ])
}

const milkTea2 = {
  n: [ 'Oolong Milk Tea', '烏龍奶茶' ],
  id: 'fm',
  bk: 'hsl(20, 50%, 50%)',
  bki: 'hsl(20, 50%, 75%)',
  list: new ItemList([
    { id: 'ol', n: [ 'Oolong Milk Tea',              '烏龍奶茶' ],       p: 525 },
    { id: 'po', n: [ 'Peach Oolong Milk Tea',        '白桃烏龍奶茶' ],   p: 525 },
    { id: 'gr', n: [ 'Grape Oolong Milk Tea',        '白葡萄烏龍奶茶' ], p: 525 },
    { id: 'dr', n: [ 'Dark Roast Oolong Milk Tea',   '碳燒烏龍奶茶' ],   p: 525 },
    { id: 'oo', n: [ 'Osmanthus Oolong Milk Tea',    '桂花烏龍奶茶' ],   p: 525 },
    { id: 'wm', n: [ 'Winter Melon Oolong Milk Tea', '冬瓜烏龍奶茶' ],   p: 525 },
  ])
}

const latte = {
  n: [ 'Tea Latte', '鮮奶茶(拿鐵)' ],
  id: 'la',
  bk: 'hsl(320, 50%, 50%)',
  bki: 'hsl(320, 50%, 75%)',
  list: new ItemList([
    { id: 'as', n: [ 'Assam Latte',               '阿薩姆鮮奶茶' ],   p: 550 },
    { id: 'jm', n: [ 'Jasmine Latte',             '茉香鮮奶茶' ],     p: 550 },
    { id: 'ol', n: [ 'Oolong Latte',              '烏龍鮮奶茶' ],     p: 550 },
    { id: 'po', n: [ 'Peach Oolong Latte',        '白桃烏龍鮮奶茶' ],  p: 550 },
    { id: 'cl', n: [ 'Ceylon Latte',              '錫蘭鮮奶茶' ],      p: 550 },
    { id: 'gm', n: [ 'Genmaicha Latte',           '玄米鮮奶茶' ],      p: 550, x: true },
    { id: 'hj', n: [ 'Hojicha Latte',             '日式烤鮮奶茶' ],    p: 550, x: true },
    { id: 'eg', n: [ 'Earl Grey Latte',           '伯爵鮮奶茶' ],      p: 550 },
    { id: 'os', n: [ 'Osmanthus Latte',           '桂花鮮奶茶' ],      p: 550 },
    { id: 'gr', n: [ 'Grape Oolong Latte',        '白葡萄烏龍鮮奶茶' ], p: 550 },
    { id: 'dr', n: [ 'Dark Roast Oolong Latte',   '碳燒烏龍鮮奶茶' ],   p: 550 },
    { id: 'fs', n: [ 'Four Seasons Oolong Latte', '四季香鮮奶茶' ],     p: 550 },
    { id: 'oo', n: [ 'Osmanthus Oolong Latte',    '桂花烏龍鮮奶茶' ],   p: 550 },
    { id: 'rt', n: [ 'Rose Tie Guan Yin Latte',   '玫瑰鐵觀音鮮奶茶' ], p: 550 },
  ])
}

const smoothie = {
  n: [ 'Snow', '雪泡' ],
  id: 'sm',
  bk: 'hsl(0, 0%, 100%)',
  bki: 'hsl(0, 0%, 90%)',
  noSweet: true,
  noTemp: true,
  lightBk: true,
  list: new ItemList([
    { id: 'sb', n: [ 'Strawberry Snow',        '草莓雪泡' ],   p: 525 },
    { id: 'mg', n: [ 'Mango Snow',             '芒果雪泡' ],   p: 525 },
    { id: 'pe', n: [ 'Peach Snow',             '水蜜桃雪泡' ], p: 525 },
    { id: 'pf', n: [ 'Passion Fruit Snow',     '百香果雪泡' ], p: 525 },
    { id: 'hd', n: [ 'Hoenydew Snow',          '哈密​​瓜雪泡' ], p: 525 },
    { id: 'ta', n: [ 'Taro Snow',              '芋頭雪泡' ],   p: 525 },
    { id: 'gb', n: [ 'Green (Mung) Bean Snow', '綠豆牛奶冰' ], p: 525 },
    { id: 'rb', n: [ 'Red Bean Snow',          '紅豆牛奶冰' ], p: 525 },
    { id: 'lc', n: [ 'Lychee Snow',            '荔枝雪泡' ],   p: 525 },
    { id: 'yg', n: [ 'Yogurt Snow',            '優格雪泡' ],   p: 525 },
    { id: 'lm', n: [ 'Lemon Snow',             '檸檬雪泡' ],   p: 525 },
    { id: 'ma', n: [ 'Matcha Snow',            '抹茶雪泡' ],   p: 525 },
    { id: 'bs', n: [ 'Black Sesame Snow',      '黑芝麻雪泡' ], p: 525 },
    { id: 'av', n: [ 'Avocado Snow',           '牛油果雪泡' ], p: 650 },
    { id: 'mo', n: [ 'Mocha Snow',             '摩卡雪泡' ],   p: 550 },
  ])
}

const slushie = {
  n: [ 'Icy', '沙冰' ],
  id: 'sl',
  bk: 'hsl(180, 100%, 50%)',
  bki: 'hsl(180, 100%, 75%)',
  noSweet: true,
  noTemp: true,
  lightBk: true,
  list: new ItemList([
    { id: 'sb', n: [ 'Strawberry Icy',        '草莓沙冰' ],   p: 525 },
    { id: 'mg', n: [ 'Mango Icy',             '芒果沙冰' ],   p: 525 },
    { id: 'pe', n: [ 'Peach Icy',             '水蜜桃沙冰' ], p: 525 },
    { id: 'pf', n: [ 'Passion Fruit Icy',     '百香果沙冰' ], p: 525 },
    { id: 'hd', n: [ 'Hoenydew Icy',          '哈密​​瓜沙冰' ], p: 525 },
    { id: 'ta', n: [ 'Taro Icy',              '芋頭沙冰' ],   p: 525 },
    { id: 'gb', n: [ 'Green (Meng) Bean Icy', '綠豆沙冰' ],   p: 525 },
    { id: 'rb', n: [ 'Red Bean Icy',          '紅豆沙冰' ],   p: 525 },
    { id: 'lc', n: [ 'Lychee Icy',            '荔枝沙冰' ],   p: 525 },
    { id: 'yg', n: [ 'Yogurt Icy',            '優格沙冰' ],   p: 525 },
    { id: 'lm', n: [ 'Lemon Icy',             '檸檬沙冰' ],   p: 525 },
  ])
}

export const drinks = [
  juice,
  freshTea,
  flavoredTea,
  milkTea,
  milkTea2,
  latte,
  smoothie,
  slushie,
]

export const toppingTokens = {
  n: [ 'Toppings', '配料' ],
  list: new ItemList([
    { id: 'tp', n: [ 'Tapioca',             '珍珠' ],       p: 50 },
    { id: 'pd', n: [ 'Egg Pudding',         '布丁' ],       p: 50 },
    { id: 'gb', n: [ 'Green Bean',          '綠豆' ],       p: 50 },
    { id: 'rb', n: [ 'Red Bean',            '紅豆' ],       p: 50 },
    { id: 'gj', n: [ 'Grass Jelly',         '仙草' ],       p: 50 },
    { id: 'rj', n: [ 'Rainbow Jelly',       '彩虹椰果' ],   p: 50 },
    { id: 'pc', n: [ 'Pineapple Jelly',     '鳳梨椰果' ],   p: 50 },
    { id: 'lj', n: [ 'Lychee Jelly',        '荔枝椰果' ],   p: 50 },
    { id: 'cj', n: [ 'Coffee Jelly',        '咖啡椰果' ],   p: 50 },
    { id: 'ga', n: [ 'Greenapple Jelly',    '青蘋果椰果' ], p: 50 },
    { id: 'ay', n: [ 'Ai-Yu Jelly',         '愛玉' ],       p: 50 },
    { id: 'al', n: [ 'Aloe',                '蘆薈' ],       p: 50 },
    { id: 'ag', n: [ 'Agar (Crystal Boba)', '寒天' ],       p: 75 },
  ])
}
