/*****************************************************************************/
//  This is the content of the Cart page
/*****************************************************************************/

import { useEffect } from 'react'
import { io } from 'socket.io-client'
//
import { DeleteItem, OrderItem } from '../components/Modal'
import Image from '../components/Image'
import Quantity from '../components/Quantity'
import { toppingTokens } from '../language/ItemTokens'
import { formatPrice, formatSweet, formatTemp, formatText, formatToken, setBodyColor } from '../Utils'

// data: Object passed from Main
export default function Cart({data}) {
  const lang = data.lang
  const cart = data.cart

  //const navigate = useNavigate()
  const URL = process.env.REACT_APP_SERVER
  const socket = io(URL)

  useEffect(() => {
    // Set background to the default color in case it has been changed
    setBodyColor()
  })

  // Need more work...
  useEffect(() => {
    const handleBeforeUnload = () => {
      // if (socket.connected) {
      //   socket.disconnect()
      // }
      //console.log('Running unload', socket.id, 'connected', socket.connected)
    };  
    // handles when page is unloaded
    window.addEventListener('beforeunload', handleBeforeUnload)
    // cleanup function handles when component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      handleBeforeUnload();
    }
  }, [socket])

  // Display empty cart text
  if (cart.length === 0) {
    return <div>{formatToken('emptyCart', lang)}</div>
  }
  
  // Calculate the amount and the subtotal for each order
  cart.forEach((item) => {
    item.subtotal = item.p + item.toppPrice
    item.total = item.subtotal * item.qty
  })

  // Calculate the total price of all items
  const grandTotal = cart.reduce(((total, item) => total + item.total), 0)

  //const sound = new Audio('/assets/ding.mp3')

  function makeOrder(phone) {
    const order = {
      phone,
      grandTotal: formatPrice(grandTotal),
    }
    // Use tc for names
    order.items = cart.map((item) => {
      return {
        name: item.n[1],
        qty: item.qty,
        sweet: (item.sweet ? formatToken('sweet' + item.sweet, 'tc') : ''),
        temp: formatTemp(item.temp, 'tc'),
        toppings: item.toppings.reduce((s, t) => (s + ' + ' + toppingTokens.list.getName(t)[1]), ''),
      }
    })
    console.log('Make Order: ' + JSON.stringify(order))
    //console.log(socket.id, 'connected = ', socket.connected);
    socket.timeout(1000).emit('store', order, () => {
      //sound.play()
      data.setShowBanner(true)
    })
    // socket.disconnect()
    data.setCart([])
    gotoMenu()
  }

  function gotoMenu() {
    data.gotoPage('menu')
  }

  // Update the amounts when the quantity changes
  function qtyChanged(item, itemQty) {
    item.qty = itemQty
    data.setCart([...cart]) // need a new array instance or it won't update
  }

  function editItem(index) {
    // Find of the item in the Cart and send it to the Item page
    const item = data.cart.find((item) => item.index === index)
    data.setCartItem(item)
    //navigate('item')
    data.gotoPage('item')
  }
  
  // Callback function for Confirm
  function deleteItem(id) {
    data.setCart(cart.filter((item) => item.id !== id))
  }
  
  // This is the main row for the drink
  function DrinkRow({item}) {
    return (<div className='flex-container'>
      <span className='grow4'>{formatText(item.n, lang)}</span>
      <span>{formatTemp(item.temp, lang)}</span>
      <span className='sweet'>{formatSweet(item.sweet, lang)}</span>
      <span>{item.price}</span>
    </div>)
  }

  // This section contains all the selected toppings, one per row
  function ToppingRows({item}) {
    if (item.toppings.length === 0) {
      return <></>
    }
    return (<div>
      {item.toppings.map((topping, i) => {
        const t = toppingTokens.list.findItem(topping)
        return (
          <div className='flex-container' key={'i' + i}>
            <span className='grow8'>+ {formatText(t.n, lang)}</span>
            <span>{formatPrice(parseInt(t.p))}</span>
          </div>)
      })}
    </div>)
  }

  // This row shows the subtotal of the item
  // If no topping is selected, this row does not appear
  // Made it separate from ToppingRows so that the last child combinator would work
  // .cart-container > .flex-container:last-child > span:last-child
  function SubtotalRow({item}) {
    if (item.toppings.length === 0) {
      return <></>
    }
    return (<div className='flex-container blank-line'>
      <span className='grow8'>{formatToken('subtotal', lang)}</span>
      <span>{formatPrice(item.subtotal)}</span>
    </div>)
  }

  // This row shows quantity selection
  function TotalRow({item}) {
    return (<div className='flex-container blank-line'>
      <span>
        <Image cls='model-icon' id='pencil32' clicked={(e) => editItem(item.index)}/>
      </span>
      <span>
        <DeleteItem item={item} action={deleteItem} lang={lang} />
      </span>
      <span>
        <Quantity item={item} qtyChanged={qtyChanged} />
      </span>
      <span>{formatPrice(item.total)}</span>
    </div>)
  }
  
  return(<div>
    {cart.map((item, i) => (<div key={'i' + i} className='cart-container'>
      <DrinkRow item={item} />
      <ToppingRows item={item} />
      <SubtotalRow item={item} />
      <TotalRow item={item} />
    </div>))}
    <OrderItem total={grandTotal} action={makeOrder} lang={lang} />
    <div className='flex-container button more' onClick={gotoMenu}>{formatToken('addDrink', lang)}</div>
  </div>)
}
