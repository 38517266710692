/*****************************************************************************/
//  CategoryButton is the button with the drop down arrow
//    It shows/hides the CategoryBar when clicked
//  CategoryBar is the bar that displays the category selections
//    The corresponding category is selected when a button is clicked
/*****************************************************************************/

import { drinks } from '../language/ItemTokens'
import { formatText } from '../Utils';

// showCats: If true, display the dropdown/up traingle in the button
// index: Index of category (from 0 to drinks.length - 1)
// clicked: Function called when a category button is clicked
// lang: Language code
export function CategoryButton({showCats, index, clicked, lang}) {
  // Make sure the index is not out of range
  if (index < 0) index = 0
  else if (index >= drinks.length) index = drinks.length - 1
  const cat = drinks[index]
  const dropChar = (showCats === undefined ? '' : ' ' + (showCats ? '▲' : '▼'))
  return (
    <button type='button' id={index}  
            className={'category-button' + (cat.lightBk ? ' lightBk' : '') + (dropChar ? ' top' : '')}
            style={{backgroundColor: cat.bk}} 
            onClick={clicked}>
      {formatText(cat.n, lang) + dropChar}
    </button>
  )
}

export default function CategoryBar({clicked, lang}) {
  return (
    <div className='category-bar'>
      {drinks.map((cat, i) => (
        <div key={cat.id}>
          <CategoryButton index={i} clicked={clicked} lang={lang} />
        </div>
      ))}
    </div>
  )
}
