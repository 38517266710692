/*****************************************************************************/
//  This is the Banner component
//  It displays a message with a 'X' button to close the page
//  The close button is red by default by transparent if ack is true
/*****************************************************************************/

  export default function Banner({text, bkColor, lightBk, onClose, ack}) {
    return <div className='item-header' style={{backgroundColor: bkColor, color: (lightBk ? 'black' : 'white')}}>
      {text}
      <span className={'close-button' + (ack ? ' ack' : '')} onClick={onClose}>&times;</span>
    </div>
  }
