/*****************************************************************************/
//  This file contains all the strings other than the drinks and toppings
/*****************************************************************************/

export const tokens = {
    location:  [ 'We are located inside the Pleasenton 99&nbsp;Ranch&nbsp;supermarket',
                 '本店位於 Pleasenton 大華超市內' ],
    hours: [ 'Hours: 11am to 7pm Daily', '營業時間：每日上午 11 點至晚上 7 點' ],

    addToCart:  [ 'Add to Cart', '加入訂單' ],
    saveToCart: [ 'Save to Cart', '更改訂單' ],
    order:      [ 'Place Order', '下訂單' ],
    received:   [ 'Order received. Thank you!', '訂單已收到。謝謝！' ],
    addDrink:   [ 'Add Drink', '添加飲料' ],
    delete:     [ 'Remove this item from Cart?', '將這飲品從訂單中刪除嗎？' ],
    phone:      [ 'Telephone Number', '電話號碼' ],
    emptyCart:  [ 'Please select your favorite drinks', '請選擇您喜歡的飲品'],
    quantity:   [ 'Quantity can be changed in Cart', '可在訂單中更改數量' ],
    subtotal:   [ 'Subtotal', '合計' ],
    yes:        [ 'Yes', '是' ],
    no:         [ 'No', '否' ],
    //tax:        [ 'Tax', '稅' ],
    //total:      [ 'Total', '總計' ],
    //shopname:   [ 'Tea Island', '鮮茶坊' ],
    //temp:        [ 'Temp.', '溫度' ],

    sweet:      [ 'Sweet', '甜' ],
    //sweet100:   [ 'Sweet', '正常' ],
    sweet100:   [ 'Sweet', '全糖' ],
    sweet75:    [ 'Less', '少糖' ],
    sweet50:    [ 'Half', '半糖' ],
    sweet25:    [ 'Low', '微糖' ],
    sweet0:     [ 'No', '無糖' ],
 
    tempIced:   [ 'Iced', '冰' ],
    tempLess:   [ 'Less Ice', '少冰' ],
    tempNoIce:  [ 'No Ice', '無冰' ],
    tempWarm:   [ 'Warm', '溫' ],
    tempHot:    [ 'Hot', '熱' ],

     // Extra description for some drinks
    hj:         [ 'This is a roasted Japanese green tea', '這是烘焙的日本綠茶' ],
    gm:         [ 'This is a Japanese brown rice green tea', '這是日本糙米綠茶' ],

    //home:       [ 'Home', '主頁' ],
    //menu:       [ 'Menu', '餐牌', '菜單' ],
    //cart:       [ 'Cart', '訂單' ], 

    //selToppings: [ 'Prices', '配料價格' ],
    //selDrinks:   [ 'Prices', '飲料價格' ]
};

