/*****************************************************************************/
//  This is the content of the Item page
//  It is in invoked from clicking a cartItem
//  It contains the Toppings, Sweetness and Temperature components
//  The cartItem is added to the Cart from here
/*****************************************************************************/

import { useCallback, useEffect, useState } from 'react'
//
import Banner from '../components/Banner'
import Sweetness from '../components/Sweetness'
import Temperature from '../components/Temperature'
import Toppings from '../components/Toppings'
import { formatPrice, formatText, formatToken } from '../Utils'
import { toppingTokens } from '../language/ItemTokens'

export default function Item({data}) {
  const lang = data.lang
  const cartItem = data.cartItem
  //console.log('Drink: ', JSON.stringify(cartItem))

  const gotoMenu = useCallback(() => {
    data.gotoPage('menu')
  }, [data])
  
  // If this page refreshes, go to Menu instead
  useEffect(() => {
    if (cartItem && Object.keys(cartItem).length === 0) {
      gotoMenu()
    }
  }, [cartItem, gotoMenu])

  const [toppings, setToppings] = useState(cartItem.toppings)
  const [sweet, setSweet] = useState(cartItem.sweet)
  const [temp, setTemp] = useState(cartItem.temp)

  const addNew = (cartItem.index === undefined)
  const label = (addNew ? 'addToCart' : 'saveToCart')

  // Add the current item to cart
  // Create the object quan in order to call the child's function
  function addToCart() {
    let newCart
    cartItem.toppings = [...toppings]
    cartItem.sweet = sweet
    cartItem.temp = temp
    if (addNew) { // add
      cartItem.index = data.itemIndex
      data.setItemIndex(data.itemIndex + 1)
      newCart = data.cart.concat(cartItem)
      //console.log('add item ', JSON.stringify(cartItem))
    }
    else { // edit
      // The item is edited in place but need to generate a new array for setCart() to work
      newCart = data.cart.map((item) => item)
    }
    //console.log('new cart ', JSON.stringify(newCart));
    data.setCart(newCart)
    data.gotoPage('cart')
  }

  function toppingsChanged(t) {
    //console.log('toppings = ', t);
    const calcPrice = ((total, id) => (total + toppingTokens.list.getPrice(id)))
    cartItem.toppPrice = t.reduce(calcPrice, 0)
    setToppings(t)
  }

  function TotalRow({total}) {
    return (<div className='flex-container button add-to-cart' onClick={addToCart}>
      <span className='grow8'>{formatToken(label, lang)}</span>
      <span>{total}</span>
    </div>)
  }
  
  //console.log('cartItem: ', JSON.stringify(cartItem))
  const totalPrice = formatPrice(cartItem.p + cartItem.toppPrice)
  const banner = formatText(cartItem.n, lang)
               + ' (' + cartItem.price + (cartItem.toppPrice === 0 ? '' : ' + ' + formatPrice(cartItem.toppPrice)) + ')'
  return (
    <div style={{width: '100vw'}}> 
      <Banner 
        text={banner}
        bkColor={cartItem.bk} 
        lightBk={cartItem.lightBk} 
        onClose={gotoMenu}
      />
      {cartItem.x && <div className='item-extra'>{formatToken(cartItem.id.substring(0, 2), lang)}</div>}
      <Toppings data={data} toppings={toppings} changed={toppingsChanged}/>
      <div className='options-container'>
        <div className='rows-container'>
          {cartItem.sweet && <Sweetness data={data} sweet={sweet} changed={(s) => setSweet(s)}/>}
          {cartItem.temp && <Temperature data={data} temp={temp} changed={(t) => setTemp(t)}  />}
        </div>
        <div className='info-line'>{formatToken('quantity', lang)}</div>
        <TotalRow total={totalPrice} />
      </div>
    </div>
  )
}
