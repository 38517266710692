/*****************************************************************************/
//  This is the Temperature component
//  It is used on the Item page
//  It contains five settings to be selected
/*****************************************************************************/

import { useEffect } from 'react'
//
import { addOrRemoveClass, formatTemp } from '../Utils'

export const temperatures = [ 'Iced', 'Less', 'NoIce', 'Warm', 'Hot' ]

// data: Object passed from Main
// temp: Id of the currently selected temperature
// changed: Function called when another temperature is clicked
export default function Temperature({data, temp, changed}) {
  const lang = data.lang

  // Update the style when another Temperature is selected
  useEffect(() => {
    document.querySelectorAll('.group-button.temp').forEach(btn => {
      addOrRemoveClass((btn.id === temp), btn, 'selected')
    })
  }, [temp])

  function formatName(t) {
    let name = formatTemp(t, lang)
    //console.log('name = ', name, 'length = ', name.length, 'width = ', data.winWidth);
    if (lang === 'en' && name.length > 5 && data.winWidth < 400) {
      name = name.replace(' Ice', '')
    }
    return name
  }

  return (
    <div className='option-row temperature'>
      { temperatures.map((t) => (
        <button type='button' key={t} id={t} onClick={(e) => changed(e.currentTarget.id)} 
         className={'group-button temp ' + t + (t === temp ? ' selected' : '')} >
          {formatName(t)}
        </button>
      ))}
    </div>
  )
}
